




import AddProceduresCC from '/src/class-components/procedures/AddProceduresCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    AddProceduresCC
  }
})
export default class AddProcedures extends Vue {}
