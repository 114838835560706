var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-component", {
    attrs: { copyData: _vm.procedureTypeCopy, validate: true },
    on: {
      "update:copyData": function ($event) {
        _vm.procedureTypeCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.procedureTypeCopy = $event
      },
      save: _vm.save,
      "on-copy": _vm.onCopy,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "4", sm: "6", xs: "12" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "12" } }, [
                          _c("h5", [_vm._v("Global period settings")]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Preoperative period" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Preoperative period",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "",
                                              min: "0",
                                              max: "3",
                                              required: "",
                                              type: "number",
                                            },
                                            model: {
                                              value:
                                                _vm.procedureTypeCopy
                                                  .globalPeriodDaysPre,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procedureTypeCopy,
                                                  "globalPeriodDaysPre",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "procedureTypeCopy.globalPeriodDaysPre",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Postoperative period" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Postoperative period",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: "",
                                              min: "0",
                                              max: "3",
                                              required: "",
                                              type: "number",
                                            },
                                            model: {
                                              value:
                                                _vm.procedureTypeCopy
                                                  .globalPeriodDays,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procedureTypeCopy,
                                                  "globalPeriodDays",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "procedureTypeCopy.globalPeriodDays",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Global Type" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Global Type",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-select", {
                                            attrs: {
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                              options:
                                                _vm.globalPeriodTypeOptions,
                                            },
                                            model: {
                                              value:
                                                _vm.procedureTypeCopy
                                                  .globalPeriodType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procedureTypeCopy,
                                                  "globalPeriodType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procedureTypeCopy.globalPeriodType",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Justifiable write off (WRO)?",
                                },
                              },
                              [
                                _c("validation-provider", {
                                  staticClass: "demo-inline-spacing",
                                  attrs: {
                                    name: "Justifiable write off (WRO)?",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "b-form-radio",
                                            {
                                              staticClass: "pt-0 mt-0",
                                              attrs: {
                                                name: "Is WRO",
                                                value: true,
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.procedureTypeCopy.isWro,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.procedureTypeCopy,
                                                    "isWro",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "procedureTypeCopy.isWro",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Yes\n                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-form-radio",
                                            {
                                              staticClass: "pt-0 mt-0",
                                              attrs: {
                                                name: "Is WRO",
                                                value: false,
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.procedureTypeCopy.isWro,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.procedureTypeCopy,
                                                    "isWro",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "procedureTypeCopy.isWro",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  No\n                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "4", sm: "6", xs: "12" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "12" } }, [
                          _c("h5", [_vm._v("Modifiers")]),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Required modifiers" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Required modifiers",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("infinit-select", {
                                            ref: "requiredModifiersSelect",
                                            attrs: {
                                              "include-ids": [].concat(
                                                _vm.procedureTypeCopy
                                                  .requiredModifiers
                                              ),
                                              selectable: function (x) {
                                                return x.status == "Active"
                                              },
                                              repo: _vm.modifiersRepo,
                                              multiple: "",
                                              label: "code",
                                            },
                                            on: {
                                              "option:selecting":
                                                _vm.onSelected,
                                              "option:deselected":
                                                _vm.onDeselected,
                                            },
                                            model: {
                                              value:
                                                _vm.procedureTypeCopy
                                                  .requiredModifiers,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procedureTypeCopy,
                                                  "requiredModifiers",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procedureTypeCopy.requiredModifiers",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Allowed modifiers" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Allowed Modifiers",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("infinit-select", {
                                            ref: "allowedModifiersSelect",
                                            attrs: {
                                              "include-ids": [].concat(
                                                _vm.procedureTypeCopy
                                                  .allowedModifiers
                                              ),
                                              selectable: function (x) {
                                                return x.status == "Active"
                                              },
                                              repo: _vm.modifiersRepo,
                                              multiple: "",
                                              label: "code",
                                            },
                                            on: {
                                              "option:selecting":
                                                _vm.onSelected,
                                              "option:deselected":
                                                _vm.onDeselected,
                                            },
                                            model: {
                                              value:
                                                _vm.procedureTypeCopy
                                                  .allowedModifiers,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procedureTypeCopy,
                                                  "allowedModifiers",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procedureTypeCopy.allowedModifiers",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Disallowed Modifiers" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Disallowed Modifiers",
                                    rules: "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("infinit-select", {
                                            ref: "disallowedModifiersSelect",
                                            attrs: {
                                              "include-ids": [].concat(
                                                _vm.procedureTypeCopy
                                                  .disallowedModifiers
                                              ),
                                              selectable: function (x) {
                                                return x.status == "Active"
                                              },
                                              repo: _vm.modifiersRepo,
                                              multiple: "",
                                              label: "code",
                                            },
                                            on: {
                                              "option:selecting":
                                                _vm.onSelected,
                                              "option:deselected":
                                                _vm.onDeselected,
                                            },
                                            model: {
                                              value:
                                                _vm.procedureTypeCopy
                                                  .disallowedModifiers,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.procedureTypeCopy,
                                                  "disallowedModifiers",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "procedureTypeCopy.disallowedModifiers",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v
      },
      expression: "formData",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }