var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-component", {
        attrs: { copyData: _vm.procedureTypeCopy },
        on: {
          "update:copyData": function ($event) {
            _vm.procedureTypeCopy = $event
          },
          "update:copy-data": function ($event) {
            _vm.procedureTypeCopy = $event
          },
          save: _vm.save,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "6", sm: "8", xs: "12" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "Procedure Type" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Procedure Type",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder: "",
                                                  required: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.procedureTypeCopy
                                                      .procType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.procedureTypeCopy,
                                                      "procType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "procedureTypeCopy.procType",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "Description" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Description",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("b-form-textarea", {
                                                attrs: {
                                                  row: "4",
                                                  placeholder: "",
                                                  required: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.procedureTypeCopy
                                                      .description,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.procedureTypeCopy,
                                                      "description",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "procedureTypeCopy.description",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "can",
                          { attrs: { I: "read", a: "ClaimRules" } },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "Is E&M" } },
                                      [
                                        _c("validation-provider", {
                                          staticClass: "demo-inline-spacing",
                                          attrs: {
                                            name: "Is E&M",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass: "pt-0 mt-0",
                                                      attrs: {
                                                        name: "Is E&M",
                                                        value: true,
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.procedureTypeCopy
                                                            .isEm,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.procedureTypeCopy,
                                                            "isEm",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "procedureTypeCopy.isEm",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Yes\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass: "pt-0 mt-0",
                                                      attrs: {
                                                        name: "Is E&M",
                                                        value: false,
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.procedureTypeCopy
                                                            .isEm,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.procedureTypeCopy,
                                                            "isEm",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "procedureTypeCopy.isEm",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      No\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label:
                                            "Requires a unique primary diagnosis",
                                        },
                                      },
                                      [
                                        _c("validation-provider", {
                                          staticClass: "demo-inline-spacing",
                                          attrs: {
                                            name: "Primary Diagnosis",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass: "pt-0 mt-0",
                                                      attrs: {
                                                        name: "Primary Diagnosis",
                                                        value: true,
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.procedureTypeCopy
                                                            .isPrimaryDiagnosis,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.procedureTypeCopy,
                                                            "isPrimaryDiagnosis",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "procedureTypeCopy.isPrimaryDiagnosis",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Yes\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass: "pt-0 mt-0",
                                                      attrs: {
                                                        name: "Primary Diagnosis",
                                                        value: false,
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.procedureTypeCopy
                                                            .isPrimaryDiagnosis,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.procedureTypeCopy,
                                                            "isPrimaryDiagnosis",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "procedureTypeCopy.isPrimaryDiagnosis",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      No\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }